import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import withNavigationCheck from './withNavigationCheck';
import DailyRewardsBox from './DailyRewardsBox';
import EnergyBosterDilog from './EnergyBosterBox';
import { RootState } from "../_redux/reducers/rootReducer";
import {addEnergyLevelRequest, resetMiningPoints } from '../_redux/actions/postsActions';

import Footer from './Footer';
import Navbar from './Navbar';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface UserData {
    id: number;
    first_name?: string;
    last_name?: string;
    username?: string;
    language_code?: string;
    is_premium?: boolean;
}

let boostObj = { "1": "3000", "2": "12,000", "3": "48,000", "4": "192,000", "5": "768,000" } as any;


const DailyRewards: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {userDataState, energyResult } = useSelector(
        (state: RootState) => state.posts
    );
    const [open, setOpen] = React.useState(false);
    const [openEnergyBox, setOpenEnergyBox] = useState(false);
    const [energyLevel, setEnergyLevel] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const openEnergyDilog = () => setOpenEnergyBox(true);
    const closeEnergyBox = () => setOpenEnergyBox(false);
    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    useEffect(() => {
        dispatch(resetMiningPoints('' as any));
    }, [])
    
    useEffect(()=>{
        let requestPayload = {
            user_id: userDataState.user_id
        } as any;
        dispatch(addEnergyLevelRequest(requestPayload));
    },[userDataState])
    
    useEffect(() => {
        if (energyResult && !isEmpty(energyResult)) {
            setEnergyLevel(energyResult?.level);
        }

    }, [energyResult])
    return (
        <React.Fragment>
            <Navbar />
            <section className="how-to-play">
                <div className="container">
                    <div className="row">
                        <div className="col-12 earnMoreCoins">
                            <img src={require('../assets/images/crypto/dolor.png')} />
                            <h2>Earn More Coins</h2>
                        </div>
                    </div>
                    <div className="row daily-rewards-reason">
                        <div className="col-12">
                            <div className='row' style={{ padding: "10px" }}>
                                <div className="col-12">
                                    <h5>Booster: </h5>
                                </div>
                                <div onClick={openEnergyDilog} className="col-12 task-box">
                                    <span className='task-arrow-left' />
                                    <img src={require('../images/energy.png')} />
                                    <h5>Energy limit</h5>
                                    <h5>{boostObj[energyLevel]} : {energyLevel} / 5 Level</h5>
                                </div>
                                <div className="col-12">
                                    <h5>Nolubz Youtube</h5>
                                </div>
                                <div className="col-12 task-box">
                                    <span className='task-arrow-left' />
                                    <img src={require('../assets/images/crypto/youTube.png')} />
                                    <p>coming soon...</p>
                                </div>
                                <div className="col-12 task-box">
                                    <span className='task-arrow-left' />
                                    <img src={require('../assets/images/crypto/youTube.png')} />
                                    <p>coming soon...</p>
                                </div>
                                <div className="col-12">
                                    <h5>Daily Task</h5>
                                </div>
                                <div onClick={handleOpen} className="col-12 task-box">
                                    <span className='task-arrow-left' />
                                    <img src={require('../assets/images/crypto/vector-png.png')} />
                                    <p style={{paddingTop:"10px"}}>Daily Reward<br />
                                    {/* <span className='currence'>$</span> +6,649,000 */}
                                    </p>
                                </div>
                                <div className="col-12">
                                    <h5>Task List</h5>
                                </div>
                                <div className="col-12 task-box">
                                    <span className='task-arrow-left' />
                                    <img src={require('../assets/images/crypto/facebook_logo.png')} />
                                    <p>coming soon...</p>
                                </div>
                                <div className="col-12 task-box">
                                    <span className='task-arrow-left' />
                                    <img src={require('../assets/images/crypto/instagram.png')} />
                                    <p>coming soon...</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {open && <DailyRewardsBox handleClose={handleClose} />}
            {openEnergyBox && <EnergyBosterDilog setEnergyLevel={setEnergyLevel} energyLevel={energyLevel} handleClose={closeEnergyBox} />}
        </React.Fragment>
    )
};
export default withNavigationCheck(DailyRewards);