import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { RootState } from "../_redux/reducers/rootReducer";
import { getUserRankByUserRequest } from "../_redux/actions/postsActions";

let levelArgs = { 1: 'Silver', 2: 'Blue', 3: 'Red', 4: 'Purple', 5: 'Gold' } as any;
let rankArgs = ['Marshall', 'General', 'Brigadier', 'Colonel', 'Commander', 'Major', 'Captain', 'Lieutenant', 'Officer', 'Sergeant', 'Corporal', 'Private', 'Recruit'] as any;
interface UserData {
	id?: any,
	first_name?: any,
	last_name?: any,
	telegram_id?: any,
	username?: any,
	email?: string,
	password?: string,
	avatar?: string,
	created_at?: any,
	updated_at?: any,
	status?: number,
	data?: any
}

const Navbar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userRankData, getUserLevel, userDataState } = useSelector(
		(state: RootState) => state.posts
	);

	const [userLevel, setUserLevel] = useState(0);
	const [userRankName, setUserRankName] = useState('');

	const [isActive, setIsActive] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [userData, setUserData] = useState<any>(null)

	const handleScroll = () => {
		if (window.scrollY >= 200) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	};

	window.addEventListener('scroll', handleScroll);

	const toggleSearch = () => {
		setIsSearchActive(prevSearchActive => !prevSearchActive);
	}

	const isEmpty = (obj: any) => {
		return Object.keys(obj).length === 0;
	}

	const toggleNav = (isEmpty: any) => {
		setIsClicked(prevActive => !prevActive);
	}

	const calculateProgress = () => {
		const reversed = [...rankArgs].reverse();
		let levelIndex = reversed.findIndex((data) => data == userRankName)
		if (levelIndex >= reversed.length - 1) {
			return 100;
		}
		const progress = (levelIndex + 1) / 13 * 100;
		return Math.min(progress, 100);
	};

	useEffect(() => {
		if (userDataState && !isEmpty(userDataState)) {
			setUserData(userDataState);
			let requestPayload = {
				user_id: userDataState.user_id
			} as any;
			dispatch(getUserRankByUserRequest(requestPayload));
		}
	}, [userDataState])
	const navigateToleval = (page: any) => {
		navigate(`/${page}`);
	}
	useEffect(() => {
		if (getUserLevel && !isEmpty(getUserLevel)) {
			setUserLevel(getUserLevel?.user_level);
		}
	}, [getUserLevel])
	useEffect(() => {
		if (userRankData && !isEmpty(userRankData)) {
			setUserRankName(userRankData?.rank_name);
			const reversed = [...rankArgs].reverse();

		}
	}, [userRankData])
	const randomNumbers: number[] = Array(userLevel).fill(undefined);
	const reversed = [...rankArgs].reverse();
	let levelIndex = reversed.findIndex((data) => data == userRankName)
	return (
		<header className="header">
			<div className="overlay"></div>
			<section className="top-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content">
								<div className="left-content">
									<ul className="left-list">
										<li>
											<p>
												<a className='brandLogo' href="/">
													<img src={require('../assets/images/logo.png')} alt="" />
												</a> | <span>{userData?.username}</span>
											</p>
										</li>
									</ul>
									<ul className="top-social-links">
										<li>
											<a href="#">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-pinterest-p"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-instagram"></i>
											</a>
										</li>
									</ul>
								</div>
								<div className="right-content">
									<ul className="right-list">
										<li>
											<div className="language-selector">
												<select name="language" className="language">
													<option value="1">EN</option>
													<option value="2">IN</option>
													<option value="3">BN</option>
												</select>
											</div>
										</li>
										<li>
											<div className="notofication" data-toggle="modal" data-target="#usernotification">
												<i className="far fa-bell"></i>
											</div>
										</li>
										<li>
											<div onClick={() => navigateToleval('wallet')} className="message wallet-icon" data-toggle="modal" data-target="#usermessage">
												<i style={{fontSize:"30px",fontWeight:"bold"}} className="fas fa-wallet"></i>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="mainmenu-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<nav className="navbar navbar-expand-lg navbar-light">
								<div onClick={() => navigateToleval('game-level')} className="flex items-center w-1/3">
									<div className="w-full">
										<div style={{paddingTop:'5px'}} className="flex justify-between">
											<p className="text-sm">{userRankName}</p>
											<p className="text-sm">{
												// randomNumbers && randomNumbers.map((number, index) => {
												// 	number = index + 1;
												// 	return (
												// 		<span className={`fa fa-star level${levelArgs[number]}`}></span>
												// 	)
												// })
											} {levelIndex + 1} / 13</p>
										</div>
										<div className="flex items-center border-2 border-[#43433b] rounded-full">
											<div className="w-full h-2 bg-[#43433b]/[0.6] rounded-full">
												<div className="progress-gradient h-2 rounded-full" style={{ width: `${calculateProgress()}%` }}></div>
											</div>
										</div>
									</div>
								</div>
								<button onClick={toggleNav} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu"
									aria-expanded="false" aria-label="Toggle navigation">
									<span className="navbar-toggler-icon"></span>
								</button>
								{isClicked &&
									<div className="navbar-menu" id="main_menu">
										<ul className="navbar-nav ml-auto">
											<li className="nav-item">
												<a onClick={() => navigateToleval('lesson')} className="nav-link" href="javaScript:void(0)">Lesson</a>
											</li>
											<li className="nav-item">
												<a onClick={() => navigateToleval('channel')} className="nav-link" href="javaScript:void(0)">Channel</a>
											</li>
											<li className="nav-item">
												<a onClick={() => navigateToleval('support')} className="nav-link" href="javaScript:void(0)">Support</a>
											</li>
										</ul>
									</div>
								}
							</nav>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Navbar;