import { postTypes } from "../Actiontypes/postsTypes";
import {
  FetchPostsFailure,
  FetchPostsFailurePayload,
  FetchPostsRequest,
  FetchPostsSuccess,
  FetchPostsSuccessPayload,
  CreateUserPayload,
  UserCreatRequest,
  FetchUserData,
  FetchUserDataPayload,
  UserPointRequest,
  FetchUserPointDataPayload,
  UserCalculatePointRequest,
  FetchUserPointData,
  ProgresPayload,
  CalculatePointResponcePayload,
  CalculatePointResponce,
  ApiProgressRequest,
  UserMiningPayload,
  UserMiningResponce,
  UserMiningReqPayload,
  UserMiningRequest,
  ClaimRewardRequestPayload,
  ClaimRewardRequest,
  ClaimRewardResponsePayload,
  ClaimRewardResponse,
  GetWalletBalanceRequestPayload,
  GetWalletBalanceRequest,
  GetWalletBalanceResponcePayload,
  GetWalletBalanceResponce,
  UserLevelRequestPayload,
  UserLevelRequest,
  UserLevelResponcePayload,
  UserLevelResponce,
  UserRankRequestPayload,
  UserRankRequest,
  UserRankResponcePayload,
  UserRankResponce,
  EnergyRequestPayload,
  EnergyLevelRequest,
  EnergyLevelResponcePayload,
  EnergyLevelResponce,
  UpdateEnergyLevelPayload,
  UpdateEnergyLevelRequest,
  UpdateEnergyLevelResponcePayload,
  UpdateEnergyLevelResponce,
  ResetMiningResponce,
  GetRankResponce,
  GetRankResponcePayload,
  GetRankRequestPayload,
  GetRankRequest,
  GetDelyRewardPayload,
  DelyRewardRequest,
  DelyRewardResponcePayload,
  DelyRewardResponce,
  CheckDealyClameRequest,
  CheckDealyClameResponce,
  ClaimDailyRewardRequest,
  ClaimDailyRewardResponce,
  GetFriendsListRequest,
  GetFriendsListResponce,
  WithdrawSystemRequestPayload,
  WithdrawSystemRequest,
  WithdrawSystemResponcePayload,
  WithdrawSystemResponce,
  WalletAddressRequestPayload,
  WalletAddressRequest,

  WalletAddressResponcePayload,
  WalletAddressResponce


} from "../types/types";

export const fetchPostsRequest = (): FetchPostsRequest => ({
  type: postTypes.FETCH_POST_REQUEST
});

export const fetchPostsSuccess = (
  payload: FetchPostsSuccessPayload
): FetchPostsSuccess => ({
  type: postTypes.FETCH_POST_SUCCESS,
  payload
});

export const fetchUserData = (
  payload: FetchUserDataPayload
): FetchUserData => ({
  type: postTypes.FETCH_USER_DATA,
  payload
});

export const fetchUserPointData = (
  payload: FetchUserPointDataPayload
): FetchUserPointData => ({
  type: postTypes.FETCH_USER_POINT_DATA,
  payload
});

export const userCalculatePointsResponce = (
  payload: CalculatePointResponcePayload
): CalculatePointResponce => ({
  type: postTypes.USER_CALCULATE_POINT_RESPONCE,
  payload
});

export const claimRewardRequestByUser = (
  payload: ClaimRewardRequestPayload
): ClaimRewardRequest => ({
  type: postTypes.CLAIM_REWARD_REQUEST,
  payload
});

export const getWalletBalanceRequest = (
  payload: GetWalletBalanceRequestPayload
): GetWalletBalanceRequest => ({
  type: postTypes.GET_WALLET_BALANCE_REQUEST,
  payload
});

export const getWalletBalanceResponce = (
  payload: GetWalletBalanceResponcePayload
): GetWalletBalanceResponce => ({
  type: postTypes.GET_WALLET_BALANCE_RESPONCE,
  payload
});

export const claimRewardResponse = (
  payload: ClaimRewardResponsePayload
): ClaimRewardResponse => ({
  type: postTypes.CLAIM_REWARD_RESPONSE,
  payload
});

export const userMiningPointsRequest = (
  payload: UserMiningReqPayload
): UserMiningRequest => ({
  type: postTypes.USER_MINING_POINTS_REQUEST,
  payload
});

export const responceMiningPoints = (
  payload: UserMiningPayload
): UserMiningResponce => ({
  type: postTypes.USER_CALCULATE_MINING_POINTS,
  payload
});

export const resetMiningPoints = (
  payload: UserMiningPayload
): ResetMiningResponce => ({
  type: postTypes.RESET_MINING_POINTS,
  payload
});

export const setProgress = (
  payload: any
): ApiProgressRequest => ({
  type: postTypes.SET_PROGRESS,
  payload
});

export const userPointRequest = (
  payload: FetchUserDataPayload
): UserPointRequest => ({
  type: postTypes.USER_POINT_REQUEST,
  payload
});

export const userCalculatePointsRequest = (
  payload: FetchUserDataPayload
): UserCalculatePointRequest => ({
  type: postTypes.USER_CALCULATE_POINTS_REQUEST,
  payload
});

export const createUserRequest = (
  payload: CreateUserPayload
): UserCreatRequest => ({
  type: postTypes.CREATE_USER_REQUEST,
  payload
});

export const fetchPostsFailure = (
  payload: FetchPostsFailurePayload
): FetchPostsFailure => ({
  type: postTypes.FETCH_POST_FAILURE,
  payload
});

export const getUserLevelRequest = (
  payload: UserLevelRequestPayload
): UserLevelRequest => ({
  type: postTypes.GET_USER_LEVEL_REQUEST,
  payload
});

export const getUserLevelResponce = (
  payload: UserLevelResponcePayload
): UserLevelResponce => ({
  type: postTypes.GET_USER_LEVEL_RESPONCE,
  payload
});

export const getUserRankRequest = (
  payload: UserRankRequestPayload
): UserRankRequest => ({
  type: postTypes.USER_RANK_REQUEST,
  payload
});

export const getUserRankResponce = (
  payload: UserRankResponcePayload
): UserRankResponce => ({
  type: postTypes.USER_RANK_RESPONCE,
  payload
});

export const addEnergyLevelRequest = (
  payload: EnergyRequestPayload
): EnergyLevelRequest => ({
  type: postTypes.ADD_ENERGY_LEVEL_REQUEST,
  payload
});

export const addEnergyLevelResponce = (
  payload: EnergyLevelResponcePayload
): EnergyLevelResponce => ({
  type: postTypes.ADD_ENERGY_LEVEL_RESPONCE,
  payload
});


export const updateEnergyLevelRequest = (
  payload: UpdateEnergyLevelPayload
): UpdateEnergyLevelRequest => ({
  type: postTypes.UPDATE_ENERGY_LEVEL_REQUEST,
  payload
});

export const updateEnergyLevelResponce = (
  payload: UpdateEnergyLevelResponcePayload
): UpdateEnergyLevelResponce => ({
  type: postTypes.UPDATE_ENERGY_LEVEL_RESPONCE,
  payload
});

export const getUserRankByUserRequest = (
  payload: GetRankRequestPayload
): GetRankRequest => ({
  type: postTypes.GET_USER_RANK_REQUEST,
  payload
});

export const getUserRankByUserResponce = (
  payload: GetRankResponcePayload
): GetRankResponce => ({
  type: postTypes.GET_USER_RANK_RESPONCE,
  payload
});

export const getDelyRewardValueRequest = (
  payload: GetDelyRewardPayload
): DelyRewardRequest => ({
  type: postTypes.GET_DELY_REWARD_VALUE_REQUEST,
  payload
});

export const getDelyRewardValueResponce = (
  payload: DelyRewardResponcePayload
): DelyRewardResponce => ({
  type: postTypes.GET_DELY_REWARD_VALUE_RESPONCE,
  payload
});

export const checkDealyClameRequest = (
  payload: GetDelyRewardPayload
): CheckDealyClameRequest => ({
  type: postTypes.CHECK_DEALY_CLAME_REQUEST,
  payload
});

export const checkDealyClameResponce = (
  payload: DelyRewardResponcePayload
): CheckDealyClameResponce => ({
  type: postTypes.CHECK_DEALY_CLAME_RESPONCE,
  payload
});

export const claimDailyRewardRequest = (
  payload: GetDelyRewardPayload
): ClaimDailyRewardRequest => ({
  type: postTypes.CLAME_DEALY_DAILY_REQUEST,
  payload
});

export const claimDailyRewardResponce = (
  payload: DelyRewardResponcePayload
): ClaimDailyRewardResponce => ({
  type: postTypes.CLAME_DEALY_DAILY_RESPONCE,
  payload
});


export const getFriendsListRequest = (
  payload: GetDelyRewardPayload
): GetFriendsListRequest => ({
  type: postTypes.GET_FRIEND_LIST_REQUEST,
  payload
});

export const getFriendsListResponce = (
  payload: DelyRewardResponcePayload
): GetFriendsListResponce => ({
  type: postTypes.GET_FRIEND_LIST_RESPONCE,
  payload
});

export const withdrawSystemRequest = (
  payload: WithdrawSystemRequestPayload
): WithdrawSystemRequest => ({
  type: postTypes.WITHDRAW_SYSTEM_REQUEST,
  payload
});

export const withdrawSystemResponce = (
  payload: WithdrawSystemResponcePayload
): WithdrawSystemResponce => ({
  type: postTypes.WITHDRAW_SYSTEM_RESPONCE,
  payload
});

export const updateWalletRequest = (
  payload: WalletAddressRequestPayload
): WalletAddressRequest => ({
  type: postTypes.UPDATE_WALLET_REQUEST,
  payload
});

export const updateWalletResponce = (
  payload: WalletAddressResponcePayload
): WalletAddressResponce => ({
  type: postTypes.UPDATE_WALLET_RESPONCE,
  payload
});