import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';
import withNavigationCheck from './withNavigationCheck';
import { updateWalletRequest, resetMiningPoints } from '../_redux/actions/postsActions';
import Footer from './Footer';
import Navbar from './Navbar';
import ReferralSystem from './ReferralSystem';
import { RootState } from "../_redux/reducers/rootReducer";

import ReactJsAlert from "reactjs-alert"

interface InputRequestData {
    user_id: number | undefined;
    coin_name?: string | undefined;
    coin_address?: string | undefined;
    coin_network?: string | undefined;
}

const WalletSystem: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateWalletResponce, isProgress, userDataState, withdrawResponce, walletBalanceResult } = useSelector(
        (state: RootState) => state.posts
    );
    const [isAlertActive, setIsAlertActive] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false);
    const [isWalletUpdates, setIsWalletUpdates] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [withDrawField, setWithDrawField] = useState<InputRequestData | any>(null)

    useEffect(() => {
        dispatch(resetMiningPoints('' as any));
    }, [])

    const withDrawInput = (event: any) => {
        if (event.target.value == '') {
            setWalletAddress('');
        }
        setWithDrawField({ ...withDrawField, [`${event.target.name}`]: event.target.value });
    }
    const isAlertActiveFun = () => {
        setIsAlertActive(false);
    }
    const submitHandler = (event: any) => {
        event.preventDefault();
        setIsSubmit(true);
        dispatch(updateWalletRequest(withDrawField))
    }
    useEffect(() => {
        setWalletAddress(userDataState?.wallet_address);
        setWithDrawField({ ...withDrawField, [`wallet_address`]: userDataState?.wallet_address, [`user_id`]: userDataState?.id });
    }, [])

    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        if (isSubmit && (updateWalletResponce && !isEmpty(updateWalletResponce))) {
            if (updateWalletResponce?.message == "success") {
                setIsAlertActive(true);
                setIsWalletUpdates(true);
                setTimeout(() => {
                    navigate(`/`);
                }, 2000)
            }
        }
    }, [updateWalletResponce])
    return (
        <React.Fragment>
            <Navbar />
            <section style={{ paddingTop: "180px" }} className="game-play-section">
                <div className="container" style={{ height: "820px" }}>
                    <div className="row">
                        <div className="col-12">
                            {walletAddress == '' && <p>Paste your OPTIMISM (OP Mainnet) address</p>}
                            <p>*Ensure this address is an OP Mainnet address from your Binance / Bybit / Trust Wallet</p>
                            {walletAddress == '' &&
                                <div>
                                    <p>*We are NOT liable for any lost of funds if you enter a wrong address or a wallet other than Binance/Bybit or Trust Wallet</p>
                                    <p>**NOTE: Auto Connect Wallet coming soon…</p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12">
                            <form action="#" onSubmit={submitHandler}>
                                <div className="form-group">
                                    <label>Wallet Address:</label>
                                    <input value={withDrawField && withDrawField['wallet_address']} onChange={withDrawInput} name='wallet_address' required={true} type="text" className="form-control" id="wallet_address" />
                                </div>
                                <div className="form-group">
                                    {isProgress && <ReactLoading type="balls" color="#fff" height={'10%'} width={'30%'} />}
                                    {walletAddress !== '' ?
                                        ''
                                        :
                                        <button style={{ color: "#ffffff", background: "#0276b8" }} type="submit" className="btn btn-default"><span>Submit</span></button>
                                    }

                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <ReactJsAlert
                    status={isAlertActive}
                    type="success"
                    title="You have successfully updated wallet address!"
                    Close={() => isAlertActiveFun()}
                />
            </section>
            <Footer />
        </React.Fragment>
    )
};
export default withNavigationCheck(WalletSystem);