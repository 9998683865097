import React, { useState, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import 'jquery/dist/jquery.min.js';

import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './assets/css/bootstrap.min.css';
import './assets/css/plugin.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
//import LocationProvider from "providers/location-provider"

import './App.css';
import AppsPage from "./components/AppsPage";
import DailyRewards from "./components/DailyRewards";
import GameLevel from "./components/GameLevel";
import Lesson from "./components/lesson";
import Channel from "./components/channel";
import Support from "./components/support";
import ReferFriend from "./components/ReferFriend";
import WithdrawSystem from "./components/WithdrawSystem";
import Wallet from "./components/Wallet";
import Word from "./components/lesson";
// import { DevelopersPage } from "./pages/developers"
// import { FavoritesPage } from "./pages/favorites"
// import { SearchPage } from "./pages/search"

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    // Simulate loading time for component logic
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 2500); // Simulates loading delay

    return () => clearTimeout(timer);
  }, []);
  if (isLoaded) {
    return (
      // <BrowserRouter basename="/austin-kombat">
      <BrowserRouter>
        <Routes>
          <Route index element={<AppsPage />} />
          <Route path="/game-level" element={<GameLevel />} />
          <Route path="/task" element={<DailyRewards />} />
          <Route path="/lesson" element={<Lesson />} />
          <Route path="/channel" element={<Channel />} />
          <Route path="/support" element={<Support />} />
          <Route path="/refer-friend" element={<ReferFriend />} />
          <Route path="/withdraw" element={<WithdrawSystem />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/word" element={<Word />} />
        </Routes>
      </BrowserRouter>
    )
  } else {
    return (
      <div className="preload-background">
        <img src={require('./images/preload-background.png')} />
      </div>
    )
  }
}

export default App