import { postTypes } from "../../Actiontypes/postsTypes";
import { PostsActions, PostsState } from "../../types/types";

const initialState: PostsState = {
  pending: false,
  posts: [],
  error: null,
  userDataState: {},
  userPointData: null,
  pointResponceData: {},
  isProgress: true,
  userMiningData: {},
  claimRewardResult: {},
  walletBalanceResult: {},
  getUserLevel: {},
  getUserRankList: {},
  energyResult: {},
  updateEnergyResult: {},
  userRankData: {},
  getDelyReward: {},
  checkDealyClameData: {},
  dealyClameResponce: {},
  getFriendsListData: {},
  withdrawResponce: {},
  updateWalletResponce: {}
};

export default (state = initialState, action: PostsActions) => {
  switch (action.type) {
    case postTypes.SET_PROGRESS:
      return {
        ...state,
        isProgress: action.payload
      };
    case postTypes.GET_DELY_REWARD_VALUE_RESPONCE:
      return {
        ...state,
        pending: false,
        getDelyReward: action.payload,
        error: null
      };
    case postTypes.WITHDRAW_SYSTEM_RESPONCE:
      return {
        ...state,
        pending: false,
        withdrawResponce: action.payload,
        error: null
      };
    case postTypes.UPDATE_WALLET_RESPONCE:
      return {
        ...state,
        pending: false,
        updateWalletResponce: action.payload,
        error: null
      };
    case postTypes.CLAME_DEALY_DAILY_RESPONCE:
      return {
        ...state,
        pending: false,
        dealyClameResponce: action.payload,
        error: null
      };
    case postTypes.GET_FRIEND_LIST_RESPONCE:
      return {
        ...state,
        pending: false,
        getFriendsListData: action.payload,
        error: null
      };
    case postTypes.CHECK_DEALY_CLAME_RESPONCE:
      return {
        ...state,
        pending: false,
        checkDealyClameData: action.payload,
        error: null
      };
    case postTypes.UPDATE_ENERGY_LEVEL_RESPONCE:
      return {
        ...state,
        pending: false,
        updateEnergyResult: action.payload,
        error: null
      };
    case postTypes.ADD_ENERGY_LEVEL_RESPONCE:
      return {
        ...state,
        pending: false,
        energyResult: action.payload,
        error: null
      };
    case postTypes.GET_WALLET_BALANCE_RESPONCE:
      return {
        ...state,
        pending: false,
        walletBalanceResult: action.payload,
        error: null
      };
    case postTypes.USER_RANK_RESPONCE:
      return {
        ...state,
        pending: false,
        getUserRankList: action.payload,
        error: null
      };
    case postTypes.GET_USER_LEVEL_RESPONCE:
      return {
        ...state,
        pending: false,
        getUserLevel: action.payload,
        error: null
      };
    case postTypes.CLAIM_REWARD_RESPONSE:
      return {
        ...state,
        pending: false,
        claimRewardResult: action.payload,
        error: null
      };
    case postTypes.FETCH_USER_DATA:
      return {
        ...state,
        pending: false,
        userDataState: action.payload,
        error: null
      };
    case postTypes.USER_CALCULATE_POINT_RESPONCE:
      return {
        ...state,
        pending: false,
        pointResponceData: action.payload,
        error: null
      };
    case postTypes.USER_CALCULATE_MINING_POINTS:
      return {
        ...state,
        pending: false,
        userMiningData: action.payload,
        error: null
      };
    case postTypes.GET_USER_RANK_RESPONCE:
      return {
        ...state,
        pending: false,
        userRankData: action.payload,
        error: null
      };
    case postTypes.RESET_MINING_POINTS:
      return {
        ...state,
        pending: false,
        userMiningData: initialState.userMiningData,
        energyResult: initialState.energyResult,
        error: null
      };
    case postTypes.FETCH_USER_POINT_DATA:
      return {
        ...state,
        pending: false,
        userPointData: action.payload,
        error: null
      };
    case postTypes.FETCH_POST_FAILURE:
      return {
        ...state,
        pending: false,
        posts: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
