import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import { IPost } from "../../models/IPost";
import {
  getUserLevelApiCall,
  getWalletBalanceApiCall,
  claimRewardApiCall,
  calculateMiningPoints,
  createUserApiCall,
  getUserPointApiCall,
  userCalculatePointsApiCall,
  getUserRankApiCall,
  addEnergyLevelApiCall,
  updateEnergyLevelApiCall,
  getUserRankByUserApiCall,
  getDelyRewardValueApiCall,
  checkDealyClameApiCall,
  claimDailyRewardApiCall,
  getFriendsListApiCall,
  withdrawSystemApiCall,
  walletAddressApiCall
} from '../services';

import {
  setProgress,
  claimRewardResponse,
  responceMiningPoints,
  fetchUserData,
  fetchUserPointData,
  userCalculatePointsResponce,
  getWalletBalanceResponce,
  getUserLevelResponce,
  getUserRankResponce,
  addEnergyLevelResponce,
  updateEnergyLevelResponce,
  getUserRankByUserResponce,
  getDelyRewardValueResponce,
  checkDealyClameResponce,
  claimDailyRewardResponce,
  getFriendsListResponce,
  withdrawSystemResponce,
  updateWalletResponce
} from "../actions/postsActions";
import { postTypes } from "../Actiontypes/postsTypes";

export interface ResponseGenerator {
  id?: any,
  first_name?: any,
  last_name?: any,
  telegram_id?: any,
  username?: any,
  email?: string,
  password?: string,
  avatar?: string,
  created_at?: any,
  updated_at?: any,
  status?: number,
  data?: any
}

export interface GetTotalPointResponse {
  status?: any,
  total_earning?: any,
  data: any
}

function* getuserMiningData(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: GetTotalPointResponse = yield calculateMiningPoints(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(responceMiningPoints(apiResponce.data))
      yield put(setProgress(false))
    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    console.log('errorerror+++', error);
    yield put(setProgress(false))
  }

}

function* getUserPointRequest(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: GetTotalPointResponse = yield getUserPointApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(fetchUserPointData(apiResponce.data))
      yield put(setProgress(false))
    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    console.log('errorerror+++', error);
    yield put(setProgress(false))
  }

}

function* userCalculatePointsReq(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: GetTotalPointResponse = yield userCalculatePointsApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(userCalculatePointsResponce(apiResponce.data))
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }

}

function* createUser(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield createUserApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(fetchUserData(apiResponce.data))
      yield put(setProgress(false))
    } else if (apiResponce.status !== 200) {

    }
  } catch (error) {
    console.log('errorerror+++', error);
    yield put(setProgress(false))
  }

}

function* claimRewardRequestByUser(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield claimRewardApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(claimRewardResponse(apiResponce.data))
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    console.log('errorerror+++', error);
    yield put(setProgress(false))
  }

}

function* getWalletBalance(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true));
  try {
    const apiResponce: ResponseGenerator = yield getWalletBalanceApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(getWalletBalanceResponce(apiResponce.data))
      yield put(setProgress(false))
    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    console.log('errorerror+++', error);
    yield put(setProgress(false))
  }

}

function* getUserLevel(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield getUserLevelApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(getUserLevelResponce(apiResponce.data))
      yield put(setProgress(false));
    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    console.log('errorerror+++', error);
    yield put(setProgress(false))
  }

}

function* getUserRank(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield getUserRankApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(getUserRankResponce(apiResponce.data))
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }

}

function* addEnergyLevel(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield addEnergyLevelApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(addEnergyLevelResponce(apiResponce.data))
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    console.log('errorerror+++', error);
    yield put(setProgress(false))
  }

}

function* updateEnergyLevel(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield updateEnergyLevelApiCall(requestPayload);
    console.log('apiResponceapiResponce', apiResponce);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(updateEnergyLevelResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }

}

function* getUserRankByUser(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield getUserRankByUserApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(getUserRankByUserResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }

}

function* getDelyRewardValue(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield getDelyRewardValueApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(getDelyRewardValueResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }

}

function* checkDealyClame(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield checkDealyClameApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(checkDealyClameResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }

}

function* claimDailyReward(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield claimDailyRewardApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(claimDailyRewardResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }
}

function* getFriendsList(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield getFriendsListApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(getFriendsListResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }
}

function* withdrawSystem(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield withdrawSystemApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(withdrawSystemResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }
}

function* updateWalletAddress(payload: any) {
  let requestPayload = payload.payload;
  yield put(setProgress(true))
  try {
    const apiResponce: ResponseGenerator = yield walletAddressApiCall(requestPayload);
    if (apiResponce.status == 200 && apiResponce.data) {
      yield put(updateWalletResponce(apiResponce.data));
      yield put(setProgress(false))

    } else if (apiResponce.status !== 200) {
      yield put(setProgress(false))
    }
  } catch (error) {
    yield put(setProgress(false))
    console.log('errorerror+++', error);
  }
}

function* saga() {
  yield all([
    takeLatest(postTypes.CREATE_USER_REQUEST, createUser),
    takeLatest(postTypes.USER_CALCULATE_POINTS_REQUEST, userCalculatePointsReq),
    takeLatest(postTypes.USER_POINT_REQUEST, getUserPointRequest),
    takeLatest(postTypes.USER_MINING_POINTS_REQUEST, getuserMiningData),
    takeLatest(postTypes.CLAIM_REWARD_REQUEST, claimRewardRequestByUser),
    takeLatest(postTypes.GET_WALLET_BALANCE_REQUEST, getWalletBalance),
    takeLatest(postTypes.GET_USER_LEVEL_REQUEST, getUserLevel),
    takeLatest(postTypes.USER_RANK_REQUEST, getUserRank),
    takeLatest(postTypes.ADD_ENERGY_LEVEL_REQUEST, addEnergyLevel),
    takeLatest(postTypes.UPDATE_ENERGY_LEVEL_REQUEST, updateEnergyLevel),
    takeLatest(postTypes.GET_USER_RANK_REQUEST, getUserRankByUser),
    takeLatest(postTypes.GET_DELY_REWARD_VALUE_REQUEST, getDelyRewardValue),
    takeLatest(postTypes.CHECK_DEALY_CLAME_REQUEST, checkDealyClame),
    takeLatest(postTypes.CLAME_DEALY_DAILY_REQUEST, claimDailyReward),
    takeLatest(postTypes.GET_FRIEND_LIST_REQUEST, getFriendsList),
    takeLatest(postTypes.WITHDRAW_SYSTEM_REQUEST, withdrawSystem),
    takeLatest(postTypes.UPDATE_WALLET_REQUEST,updateWalletAddress)
  ]);
}
export default saga;
