import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../_redux/reducers/rootReducer";
import { getWalletBalanceRequest, updateEnergyLevelRequest } from '../_redux/actions/postsActions';

let pointsToSpend = { "1": "3000", "2": "30000", "3": "150000", "4": "750000", "5": "3750000" } as any;

type Props = {
    showModal?: boolean,
    energyLevel?: any,
    handleClose: any,
    children?: React.ReactNode,
    setEnergyLevel?: any
}

const EnergyBosterBox = ({ setEnergyLevel, handleClose, energyLevel }: Props) => {
    const { updateEnergyResult, isProgress, userDataState, walletBalanceResult } = useSelector(
        (state: RootState) => state.posts
    );
    const [isBoostButtonActive, setIsBoostButtonActive] = useState(false);
    const [coinTospend, setCoinTospend] = useState(0);
    const [updateEnergy, setUpdateEnergy] = useState(0);
    const [isUpdateMessage, setIsUpdateMessage] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    useEffect(() => {
        if (walletBalanceResult && !isEmpty(walletBalanceResult)) {
            let energryLevel = (parseInt(energyLevel) + 1);
            let spendCoin = pointsToSpend[energryLevel];
            if (Math.abs(walletBalanceResult.wallet_balance) >= Math.abs(spendCoin)) {
                setIsBoostButtonActive(true);
                setCoinTospend(spendCoin);
                setUpdateEnergy(energryLevel);
            }
        }
    }, [walletBalanceResult])
    useEffect(() => {
        if (updateEnergyResult && !isEmpty(updateEnergyResult) && isClicked) {
            let requestPayload = {
                user_id: userDataState.user_id
            } as any;
            dispatch(getWalletBalanceRequest(requestPayload));
            setIsUpdateMessage(true);
            setTimeout(() => {
                setIsUpdateMessage(false);
                handleClose();
            }, 2000)
            setEnergyLevel(updateEnergyResult.data.level);
        }
    }, [updateEnergyResult])
    const bootsYourEnergy = () => {
        let requestPayLoad = {
            user_id: userDataState?.user_id,
            level: updateEnergy,
            coin_to_spend: coinTospend
        } as any;
        dispatch(updateEnergyLevelRequest(requestPayLoad));
        setIsClicked(true);
    }
    const formatNumberWithoutFunctions = (num: any) => {
        // Convert the number to a string
        let numStr = num.toString();

        // Split the string into the integer and decimal parts (if any)
        let parts = numStr.split('.');
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? parts[1] : '';

        // Reverse the integer part to process the commas from right to left
        let reversedInteger = '';
        for (let i = integerPart.length - 1; i >= 0; i--) {
            reversedInteger += integerPart[i];
        }

        // Add commas every three digits
        let withCommasReversed = '';
        for (let i = 0; i < reversedInteger.length; i++) {
            if (i > 0 && i % 3 === 0) {
                withCommasReversed += ',';
            }
            withCommasReversed += reversedInteger[i];
        }

        // Reverse the string back to its original order
        let formattedInteger = '';
        for (let i = withCommasReversed.length - 1; i >= 0; i--) {
            formattedInteger += withCommasReversed[i];
        }

        // Combine the integer and decimal parts (if any)
        return decimalPart ? formattedInteger + '.' + decimalPart : formattedInteger;
    }

    return (
        <div className='modal-overlay'>
            <div className='modal-box-content'>
                <div className='modal-box-main-content'>
                    <div className='modal-box-header'>
                        <span onClick={handleClose} className='close-modal-box'>X</span>
                        <img src={require('../images/energy.png')} />
                        <h3>Energy Limit</h3>
                        <p>Increase the limit of energy storage</p>

                    </div>
                    <div className='modal-box-container boots-container'>
                        {
                            isUpdateMessage ?
                                <div className="pyro">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div> : ''
                        }
                        {isBoostButtonActive ?
                            <div>
                                <p>Spend {formatNumberWithoutFunctions(coinTospend)} coins to update energy level {updateEnergy}</p>
                                <button onClick={bootsYourEnergy}>Boots Your Energy</button>
                            </div>
                            : <h4>Insufficient Funds</h4>}
                        {isProgress && <ReactLoading type="balls" color="#fff" height={'10%'} width={'30%'} />}

                    </div>
                </div>
                <div className='modal-box-footer'>


                </div>
            </div>

        </div>
    )
};
export default EnergyBosterBox;