export enum postTypes {
  FETCH_POST_REQUEST = "FETCH_POST_REQUEST",
  SET_PROGRESS = "SET_PROGRESS",
  CREATE_USER_REQUEST = "CREATE_USER_REQUEST",
  FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS",
  FETCH_USER_DATA = "FETCH_USER_DATA",
  FETCH_POST_FAILURE = "FETCH_POST_FAILURE",
  USER_POINT_REQUEST = "USER_POINT_REQUEST",
  USER_CALCULATE_POINTS_REQUEST = "USER_CALCULATE_POINTS_REQUEST",
  FETCH_USER_POINT_DATA = "FETCH_USER_POINT_DATA",
  USER_CALCULATE_POINT_RESPONCE = "USER_CALCULATE_POINT_RESPONCE",
  USER_CALCULATE_MINING_POINTS = "USER_CALCULATE_MINING_POINTS",
  USER_MINING_POINTS_REQUEST = "USER_MINING_POINTS_REQUEST",
  CLAIM_REWARD_REQUEST = "CLAIM_REWARD_REQUEST",
  CLAIM_REWARD_RESPONSE = "CLAIM_REWARD_RESPONSE",
  GET_WALLET_BALANCE_REQUEST = "GET_WALLET_BALANCE_REQUEST",
  GET_WALLET_BALANCE_RESPONCE = "GET_WALLET_BALANCE_RESPONCE",
  GET_USER_LEVEL_REQUEST = "GET_USER_LEVEL_REQUEST",
  GET_USER_LEVEL_RESPONCE = "GET_USER_LEVEL_RESPONCE",
  USER_RANK_RESPONCE = "USER_RANK_RESPONCE",
  USER_RANK_REQUEST = "USER_RANK_REQUEST",
  ADD_ENERGY_LEVEL_REQUEST = "ADD_ENERGY_LEVEL_REQUEST",
  ADD_ENERGY_LEVEL_RESPONCE = "ADD_ENERGY_LEVEL_RESPONCE",
  UPDATE_ENERGY_LEVEL_REQUEST = "UPDATE_ENERGY_LEVEL_REQUEST",
  UPDATE_ENERGY_LEVEL_RESPONCE = "UPDATE_ENERGY_LEVEL_RESPONCE",
  RESET_MINING_POINTS = "RESET_MINING_POINTS",
  GET_USER_RANK_RESPONCE = "GET_USER_RANK_RESPONCE",
  GET_USER_RANK_REQUEST = "GET_USER_RANK_REQUEST",
  GET_DELY_REWARD_VALUE_REQUEST = "GET_DELY_REWARD_VALUE_REQUEST",
  GET_DELY_REWARD_VALUE_RESPONCE = "GET_DELY_REWARD_VALUE_RESPONCE",
  CHECK_DEALY_CLAME_RESPONCE = "CHECK_DEALY_CLAME_RESPONCE",
  CHECK_DEALY_CLAME_REQUEST = "CHECK_DEALY_CLAME_REQUEST",
  CLAME_DEALY_DAILY_REQUEST = "CLAME_DEALY_DAILY_REQUEST",
  CLAME_DEALY_DAILY_RESPONCE = "CLAME_DEALY_DAILY_RESPONCE",
  GET_FRIEND_LIST_REQUEST = "GET_FRIEND_LIST_REQUEST",
  GET_FRIEND_LIST_RESPONCE = "GET_FRIEND_LIST_RESPONCE",
  WITHDRAW_SYSTEM_REQUEST = "WITHDRAW_SYSTEM_REQUEST",
  WITHDRAW_SYSTEM_RESPONCE = "WITHDRAW_SYSTEM_RESPONCE",
  UPDATE_WALLET_REQUEST = "UPDATE_WALLET_REQUEST",
  UPDATE_WALLET_RESPONCE = "UPDATE_WALLET_RESPONCE"
}