import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
const Footer = () => {
    const navigate = useNavigate();
    const navigateToPage = (page:any) => {
		navigate(`/${page}`);
	}
    return (
        <footer className="footer" id="footer">
            <div className="footer-menu">
                <ul>
                    <li><a onClick={()=>navigateToPage('')} href="#">Home</a>
                    </li>
                    <li><a onClick={()=>navigateToPage('task')} href="#">Task</a>
                    </li>
                    <li><a onClick={()=>navigateToPage('refer-friend')} href="#">Friend</a>
                    </li>
                    <li><a onClick={()=>navigateToPage('word')} href="#">Word</a>
                    </li>
                    <li><a onClick={()=>navigateToPage('withdraw')} href="#">withdraw</a>
                    </li>
                </ul>
            </div>

        </footer>
    )
}
export default Footer;
