import axios from 'axios';
let apiUrl = `https://nolubz.com/nolubz-api`;

export const getUserRankApiCall = async (request: any) => {
    const response = await axios.get(`${apiUrl}/api/getUserRank`);
    return response;
};

export const getDelyRewardValueApiCall = async (request: any) => {
    const response = await axios.get(`${apiUrl}/api/getDelyRewardValue`);
    return response;
};

export const createUserApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('telegram_id', request?.telegram_id);
    formData.append('referral_telegram_id', request?.referral_telegram_id);
    formData.append('username', request?.username);
    formData.append('first_name', request?.first_name);
    formData.append('last_name', request?.last_name);
    formData.append('registration_time', request?.registration_time);
    const response = await axios.post(`${apiUrl}/api/createUser`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const addEnergyLevelApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('level', '1');
    const response = await axios.post(`${apiUrl}/api/addEnergyLevel`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const checkDealyClameApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('week', request?.week);
    const response = await axios.post(`${apiUrl}/api/checkDealyClame`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const claimDailyRewardApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('claim_week', request?.claim_week);
    formData.append('claim_day_id', request?.claim_day_id);
    formData.append('commission_amount', request?.commission_amount);
    const response = await axios.post(`${apiUrl}/api/claimDailyReward`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const getFriendsListApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('telegram_id', request?.telegram_id);
    const response = await axios.post(`${apiUrl}/api/getFriendsList`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const walletAddressApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('wallet_address', request?.wallet_address);
    const response = await axios.post(`${apiUrl}/api/walletAddress`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const withdrawSystemApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('coin_name', request?.coin_name);
    formData.append('coin_address', request?.coin_address);
    formData.append('coin_network', request?.coin_network);
    const response = await axios.post(`${apiUrl}/api/withdrawSystem`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const updateEnergyLevelApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('level', request?.level);
    formData.append('coin_to_spend', request?.coin_to_spend);
    const response = await axios.post(`${apiUrl}/api/updateEnergyLevel`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const getUserRankByUserApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    const response = await axios.post(`${apiUrl}/api/getUserRankByUser`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const getUserPointApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    const response = await axios.post(`${apiUrl}/api/getTotalPoint`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const calculateMiningPoints = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    const response = await axios.post(`${apiUrl}/api/calculateMiningPoints`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const getWalletBalanceApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    const response = await axios.post(`${apiUrl}/api/getWalletBalance`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const claimRewardApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('commission_amount', request?.commission_amount);
    const response = await axios.post(`${apiUrl}/api/claimReward`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const getUserLevelApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    const response = await axios.post(`${apiUrl}/api/getUserLevel`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // This header is important for form data
        },
    });
    return response;
};

export const userCalculatePointsApiCall = async (request: any) => {
    const formData = new FormData();
    formData.append('user_id', request?.user_id);
    formData.append('point_creation_time', request?.point_creation_time);
    formData.append('point_update_time', request?.point_update_time);
    formData.append('point', request?.point);
    formData.append('claim_status', request?.claim_status);
    const response = await axios.post(`${apiUrl}/api/calculatePoints`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};
