import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { resetMiningPoints, addEnergyLevelRequest, getUserLevelRequest, getWalletBalanceRequest, claimRewardRequestByUser, userMiningPointsRequest, userCalculatePointsRequest, createUserRequest, userPointRequest } from "../_redux/actions/postsActions";
import { RootState } from "../_redux/reducers/rootReducer";

import Navbar from './Navbar';
import Footer from './Footer';
import Loader from './Loader';

interface UserData {
    id: number;
    first_name?: string;
    last_name?: string;
    username?: string;
    language_code?: string;
    is_premium?: boolean;
}

const AppsPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userData, setUserData] = useState<UserData | null>(null)

    const { isProgress, energyResult, getUserLevel, walletBalanceResult, userMiningData, claimRewardResult, userDataState, userPointData } = useSelector(
        (state: RootState) => state.posts
    );
    const [countdown, setCountdown] = useState<any | null>(null);
    const [points, setPoints] = useState(0);
    const [miningPoints, setMiningPoints] = useState(0);
    const [isplaysessionactive, setIsplaysessionactive] = useState(false);
    const [isActiveClameBtn, setIsActiveClameBtn] = useState(false);
    const [walletBalance, setWalletBalance] = useState('0');
    const [dailyRewards, setDailyRewards] = useState('');
    useEffect(() => {
        let currentDateAndTime = new Date();
        const formattedDate = currentDateAndTime.toISOString().slice(0, 19).replace('T', ' ');
        if (window.Telegram && window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;
            const urlParams = new URLSearchParams(tg?.initData as any);
            const startParam = urlParams.get('start_param');
            let referral_telegram_id = ''
            if (startParam) {
                referral_telegram_id = startParam;
            } else {
                referral_telegram_id = '';
            }
            console.log('referral_telegram_idreferral_telegram_id', referral_telegram_id);
            // Retrieve user information from the SDK
            const currentUser = tg.initDataUnsafe?.user;
            // Update state with user information
            if (currentUser) {
                let createUserPayload = {
                    referral_telegram_id: referral_telegram_id,
                    telegram_id: currentUser?.id,
                    username: `${currentUser.first_name}`,
                    first_name: `${currentUser.first_name}`,
                    last_name: `${currentUser.last_name}`,
                    registration_time: `${formattedDate}`
                } as any
                dispatch(createUserRequest(createUserPayload));
            }
            tg.expand();
        } else {
            let createUserPayload = {
                referral_telegram_id: '',
                telegram_id: '6945465540',
                username: 'Austin',
                first_name: 'Austin',
                last_name: 'Onuchukwu',
                registration_time: formattedDate
            } as any;
            dispatch(createUserRequest(createUserPayload));
        }
    }, []);
    const startCountdown = (hours: any) => {
        let remainingTime = hours * 60 * 60; // Convert hours to milliseconds
        const countdownInterval = setInterval(() => {
            let hours = Math.floor(remainingTime / 3600);
            let minutes = Math.floor((remainingTime % 3600) / 60);
            let seconds = remainingTime % 60;
            //let milliseconds = Number(remainingTime % 1000);
            setCountdown(
                `${hours}h ${minutes}m ${seconds.toFixed(0)}s`
            ); // Display the remaining time
            setPoints(prevPoints => prevPoints + 1);
            if (remainingTime > 0) {
                remainingTime--; // Decrease the remaining time by 1 ms
            } else {
                console.log("Time's up!");
                setIsActiveClameBtn(true);
                setIsplaysessionactive(false);
                clearInterval(countdownInterval); // Stop the countdown
            }
        }, 1000); // Run every millisecond (1ms)
    }
    const startPlay = () => {
        startCountdown(3);
        setIsplaysessionactive(true);
        let currentDateAndTime = new Date();
        const formattedDate = currentDateAndTime.toISOString().slice(0, 19).replace('T', ' ');
        let payloadData = {
            user_id: userDataState.user_id,
            point_creation_time: formattedDate,
            point_update_time: formattedDate,
            point: points,
            claim_status: 0
        } as any;
        dispatch(userCalculatePointsRequest(payloadData))
    }
    const claimPoint = () => {
        let payloadData = {
            user_id: userDataState.user_id,
            commission_amount: miningPoints !== 0 ? miningPoints : userDataState?.points
        } as any;
        setIsActiveClameBtn(false);
        setPoints(0);
        dispatch(claimRewardRequestByUser(payloadData))
    }
    useEffect(() => {
        if (points > 0) {
            localStorage.setItem('storePoints', `${points}`);
        }
    }, [points, countdown])
    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    useEffect(() => {
        if (userDataState && !isEmpty(userDataState)) {
            let requestPayload = {
                user_id: userDataState.user_id
            } as any;
            dispatch(userPointRequest(requestPayload));
            dispatch(userMiningPointsRequest(requestPayload));
            dispatch(getWalletBalanceRequest(requestPayload));
            dispatch(getUserLevelRequest(requestPayload));
            dispatch(addEnergyLevelRequest(requestPayload));
        }
        localStorage.clear();
    }, [userDataState]);

    useEffect(() => {
        if (walletBalanceResult && !isEmpty(walletBalanceResult)) {
            setWalletBalance(`${formatNumberWithoutFunctions(walletBalanceResult?.wallet_balance)}`);
        }
    }, [walletBalanceResult]);
    useEffect(() => {
        if (claimRewardResult && !isEmpty(claimRewardResult)) {
            let requestPayload = {
                user_id: userDataState.user_id
            } as any;
            dispatch(getWalletBalanceRequest(requestPayload));
        }
    }, [claimRewardResult])

    useEffect(() => {
        if (userMiningData && !isEmpty(userMiningData)) {
            if (userMiningData && userMiningData.noRecordFound == "yes") {
                setDailyRewards(`${userDataState?.points}`);
                setIsplaysessionactive(false);
                setIsActiveClameBtn(false);
            } else {
                let totalMIningHours = userMiningData?.totalHours;
                let totalMIningPoint = userMiningData?.totalMilliseconds;
                let remainingHours = Math.abs(3 - totalMIningHours);
                if (Number(totalMIningHours) <= 3) {
                    startCountdown(Number(remainingHours));
                    setIsplaysessionactive(true);
                    setPoints(totalMIningPoint);
                } else if (Number(totalMIningHours) > 3) {
                    setIsplaysessionactive(false);
                    setPoints(10800);
                    setIsActiveClameBtn(true);
                }
            }
        }
    }, [userMiningData])
    useEffect(() => {
        if (energyResult && !isEmpty(energyResult)) {
            if (energyResult?.level == 1) {
                let miningPoint = ((1 / 3.6) * points).toFixed(0) as any;
                setMiningPoints(miningPoint);
            } else if (energyResult?.level == 2) {
                let miningPoint = ((1 / 0.9) * points).toFixed(0) as any;
                setMiningPoints(miningPoint);
            } else if (energyResult?.level == 3) {
                let miningPoint = ((1 / 0.225) * points).toFixed(0) as any;
                setMiningPoints(miningPoint);
            } else if (energyResult?.level == 4) {
                let miningPoint = ((1 / 0.05625) * points).toFixed(0) as any;
                setMiningPoints(miningPoint);
            } else if (energyResult?.level == 5) {
                let miningPoint = ((1 / 0.0140625) * points).toFixed(0) as any;
                setMiningPoints(miningPoint);
            } else {
                let miningPoint = ((1 / 3.6) * points).toFixed(0) as any;
                setMiningPoints(miningPoint);
            }
        }
    }, [points, energyResult]);

    const formatNumberWithoutFunctions = (num: any) => {
        let numStr = num.toString();
        let parts = numStr.split('.');
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? parts[1] : '';
        let reversedInteger = '';
        for (let i = integerPart.length - 1; i >= 0; i--) {
            reversedInteger += integerPart[i];
        }
        let withCommasReversed = '';
        for (let i = 0; i < reversedInteger.length; i++) {
            if (i > 0 && i % 3 === 0) {
                withCommasReversed += ',';
            }
            withCommasReversed += reversedInteger[i];
        }
        let formattedInteger = '';
        for (let i = withCommasReversed.length - 1; i >= 0; i--) {
            formattedInteger += withCommasReversed[i];
        }
        return decimalPart ? formattedInteger + '.' + decimalPart : formattedInteger;
    }
    return (
        <React.Fragment>
            {isProgress ? <Loader /> : ''}
            <Navbar />
            <section className="counter-section counter-section2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="prize-pool">
                                <h3>{walletBalance}</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="game-play-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="single-play">
                                <div className="image">
                                    {
                                        isplaysessionactive ?
                                            <img src={require('../assets/images/game-play/gears.gif')} alt="" />
                                            :
                                            <img src={require('../assets/images/game-play/gears.png')} alt="" />
                                    }

                                </div>
                                <div className="contant">
                                    {
                                        isActiveClameBtn ?
                                            <a href="javascript:void(0)" onClick={claimPoint} className="mybtn1">Claim {miningPoints}</a>
                                            :
                                            isplaysessionactive ?
                                                <div className='farmingDisplay'><h6><span className='farming'>Farming : {miningPoints}</span><span className='countdown'><i className="far fa-clock"></i> {countdown}</span></h6></div>
                                                :
                                                <a href="javascript:void(0)" onClick={startPlay} className="mybtn1">FARM</a>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
};
export default AppsPage;