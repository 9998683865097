import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import withNavigationCheck from './withNavigationCheck';
import { getFriendsListRequest, resetMiningPoints } from '../_redux/actions/postsActions';
import Footer from './Footer';
import Navbar from './Navbar';
import ReferralSystem from './ReferralSystem';
import { RootState } from "../_redux/reducers/rootReducer";

interface FriendListData {
    id: number;
    first_name?: string;
    last_name?: string;
    username?: string;
    language_code?: string;
    is_premium?: boolean;
    length?: number
}

const ReferFriend: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userDataState, getFriendsListData } = useSelector(
        (state: RootState) => state.posts
    );
    const [initData, setInitData] = useState('')
    const [userId, setUserId] = useState('')
    const [startParam, setStartParam] = useState('')
    const [friendList, setFriendList] = useState<FriendListData | null>(null)

    useEffect(() => {
        dispatch(resetMiningPoints('' as any));
    }, [])

    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        let requestPayload = {
            telegram_id: userDataState.telegram_id
        } as any;
        dispatch(getFriendsListRequest(requestPayload));
    }, [userDataState])

    useEffect(() => {
        if (getFriendsListData && !isEmpty(getFriendsListData)) {
            setFriendList(getFriendsListData?.user_data);
        }
    }, [getFriendsListData])


    useEffect(() => {
        const initWebApp = async () => {
            if (typeof window !== 'undefined') {
                const WebApp = (await import('@twa-dev/sdk')).default;
                WebApp.ready();
                setInitData(WebApp.initData);
                setUserId(WebApp.initDataUnsafe.user?.id.toString() || '');
                setStartParam(WebApp.initDataUnsafe.start_param || '');
            }
        };

        initWebApp();
    }, [])
    const formatNumber = (num: any) => {
        const units = ["", "k", "M", "B", "T", "Q"];
        let unitIndex = 0;

        // Continue dividing the number by 1000 while increasing the unit index
        while (num >= 1000 && unitIndex < units.length - 1) {
            num /= 1000;
            unitIndex++;
        }

        // Format the number to 1 decimal place and remove the trailing .0
        return num.toFixed(1).replace(/\.0$/, '') + ' ' + units[unitIndex];
    }
    return (
        <React.Fragment>
            <Navbar />
            <section style={{ paddingTop: "180px" }} className="game-play-section">
                <div className="container" style={{ height: "820px" }}>
                    <div className="row">
                        <div className="col-12 earnMoreCoins">
                            <h2>Earn More Coins</h2>
                            <p>You and your friend will receive bonuses</p>
                        </div>
                    </div>
                    <div className="row daily-rewards-reason">
                        <div className="col-12">
                            <div className='row' style={{ padding: "10px" }}>
                                <div className="col-12 task-box">
                                    <span className='task-arrow-left' />
                                    <img style={{ height: "90px" }} src={require('../images/gift.png')} />
                                    <h3 style={{ marginTop: '10px' }}>Invite a friend</h3>
                                    <p><span className='currence'>$</span> +5,000 for you</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <ReferralSystem initData={initData} userId={userId} startParam={startParam} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                            <h3>More bonuses</h3>
                        </div>
                        <div className="col-12">
                            {friendList && friendList !== undefined && friendList?.length > 0 && (
                                <div className="mt-8">
                                    <ul>
                                        {friendList.map((referral: any, index: any) => {
                                            console.log('referralreferral', referral?.user_rank_detail?.rank_name);
                                            return (
                                                <li key={index} className="friend-list p-2 mb-2 rounded">
                                                    <img src={require('../images/profile-icon.png')} />
                                                    <p><span>{referral?.first_name} {referral?.last_name}</span> <span style={{ float: "right" }}><span className='currence'>$</span> {formatNumber(referral?.user_rank_detail?.amount) || 0}</span>
                                                        <br /><span>{referral?.user_rank_detail?.rank_name}</span></p>
                                                </li>

                                            )
                                        }
                                        )}
                                    </ul>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
};
export default withNavigationCheck(ReferFriend);