import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { RootState } from "../_redux/reducers/rootReducer";
import { useSelector, useDispatch } from 'react-redux';

// Higher-Order Component that injects navigation check logic
const withNavigationCheck = (WrappedComponent: any) => {
    return (props: any) => {
        const navigate = useNavigate();
        const { userDataState } = useSelector(
            (state: RootState) => state.posts
        );

        const isEmpty = (obj: any) => {
            return Object.keys(obj).length === 0;
        }

        useEffect(() => {
            if (userDataState && isEmpty(userDataState)) {
                navigate(`/`);
            }
        }, [userDataState]);

        return <WrappedComponent {...props} />;
    };
};

export default withNavigationCheck;