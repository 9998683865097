import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import { useSelector, useDispatch } from 'react-redux';
import withNavigationCheck from './withNavigationCheck';
import { RootState } from "../_redux/reducers/rootReducer";
import { claimDailyRewardRequest, checkDealyClameRequest, getDelyRewardValueRequest, getWalletBalanceRequest, claimRewardRequestByUser } from '../_redux/actions/postsActions';
type Props = {
    showModal?: boolean,
    handleClose: any,
    children?: React.ReactNode,
}

interface ClaimData {
    id: number;
    points?: any;
    last_name?: string;
    username?: string;
    language_code?: string;
    is_premium?: boolean;
}

const DailyRewardsBox = ({ handleClose }: Props) => {
    const { dealyClameResponce, checkDealyClameData, getDelyReward, userDataState, isProgress } = useSelector(
        (state: RootState) => state.posts
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dailyReward, setDailyReward] = useState([{ daily_reward: '10k' }, { daily_reward: '25k' }, { daily_reward: '50k' }, { daily_reward: '100k' }, { daily_reward: '200k' }, { daily_reward: '500k' }, { daily_reward: '1M' }]);
    const [isUpdateMessage, setIsUpdateMessage] = useState(false);
    const [isComeBack, setIsComeBack] = useState(false);
    const [isClaimBtnClick, setIsClaimBtnClick] = useState(false);
    const [dataForClaim, setDataForClaim] = useState<ClaimData | null>(null)
    const [activeClameData, setActiveClameData] = useState([]);

    const weeksBetween = (date1: any, date2: any) => {
        // Convert both dates to milliseconds
        const msInDay = 1000 * 60 * 60 * 24;
        const msInWeek = msInDay * 7;

        // Calculate the difference in time between the two dates in milliseconds
        const differenceInMs = date2 - date1;

        // Calculate whole weeks
        const weeks = Math.floor(differenceInMs / msInWeek);

        // Calculate the remaining days
        const remainingDays = Math.floor((differenceInMs % msInWeek) / msInDay);

        return { weeks, days: remainingDays };
    }

    useEffect(() => {
        const startDate = new Date(userDataState?.created_at);
        const endDate = new Date();
        const numberOfWeeks = weeksBetween(startDate, endDate);
        console.log('userDataStateuserDataStateuserDataState', userDataState);
        dispatch(getDelyRewardValueRequest('' as any))
        let requestPayload = {
            user_id: userDataState?.user_id,
            week: userDataState?.number_of_week
        } as any
        dispatch(checkDealyClameRequest(requestPayload))
    }, [userDataState])

    const getDataForomclaim = () => {
        if (getDelyReward && !isEmpty(getDelyReward)) {
            return getDelyReward;
        } else {
            return [];
        }
    }
    useEffect(() => {
        if (checkDealyClameData && !isEmpty(checkDealyClameData)) {
            if (checkDealyClameData?.data == 0) {
                let claimArgs = getDataForomclaim().find((data: any, index: number) => index == 0)
                setDataForClaim(claimArgs);
            } else {
                let getClaimArgs = getDataForomclaim() || [];
                let getFilterClaimArgs = getClaimArgs.filter((data: any, index: number) => {
                    let claimCheckArgs = checkDealyClameData?.data;
                    let claimCheckIndex = claimCheckArgs.findIndex((chkData: any) => chkData?.claim_day_id == data.id);
                    if (claimCheckIndex == -1) {
                        return data;
                    }

                })
                let claimArgs = getFilterClaimArgs.find((data: any, index: number) => index == 0)
                setDataForClaim(claimArgs);
                setActiveClameData(checkDealyClameData?.data);

            }

        }

    }, [checkDealyClameData])

    const claimPoint = () => {
        let payloadData = {
            user_id: userDataState.user_id,
            commission_amount: dataForClaim?.points || 10000,
            claim_day_id: dataForClaim?.id || 1,
            claim_week: userDataState?.number_of_week || 0
        } as any;
        dispatch(claimDailyRewardRequest(payloadData))
        let requestPayload = {
            user_id: userDataState.user_id
        } as any;
        setIsClaimBtnClick(true)
        dispatch(getWalletBalanceRequest(requestPayload));
    }
    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    useEffect(() => {
        if (dealyClameResponce && !isEmpty(dealyClameResponce)) {
            if (isClaimBtnClick && dealyClameResponce?.message == 'Daily rewards calime successful') {
                setIsUpdateMessage(true)
                setIsComeBack(true);
                setTimeout(() => {
                    setIsUpdateMessage(false);
                    navigate(`/`);
                    handleClose();
                }, 3000)
            } else {
                setIsUpdateMessage(false)
                setIsComeBack(false);
            }
        } else {
            setIsUpdateMessage(false);
            setIsComeBack(false);
        }
    }, [dealyClameResponce]);
    console.log('activeClameDataactiveClameData', activeClameData);
    return (
        <div className='modal-overlay'>
            <div className='modal-box-content'>
                <div className='modal-box-main-content'>
                    <div className='modal-box-header'>
                        <span onClick={handleClose} className='close-modal-box'>X</span>
                        <img src={require('../assets/images/crypto/vector-png.png')} />
                        <h2>Daily reward</h2>
                        <p>Accrue coins for logging into the game daily without skiping</p>

                    </div>
                    <div className='modal-box-container'>
                        {isProgress && <ReactLoading type="balls" color="#fff" height={'10%'} width={'30%'} />}
                        {
                            isUpdateMessage ?
                                <div className="pyro">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div> : ''
                        }

                        {!isProgress && getDelyReward && !isEmpty(getDelyReward) ?
                            getDelyReward.map((reward: any, index: number) => {
                                let activeIndex = activeClameData.findIndex((data: any) => data?.claim_day_id == reward.id);
                                if (activeIndex !== -1) {
                                    return (
                                        <div className='deplyRewardBox activeReward'>
                                            <p>Day {reward?.daily_reward_day}</p>
                                            <p><span className='currence'>$</span></p>
                                            <p>{reward.points_display}</p>

                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className='deplyRewardBox'>
                                            <p>Day {reward?.daily_reward_day}</p>
                                            <p><span className='currence'>$</span></p>
                                            <p>{reward.points_display}</p>

                                        </div>
                                    );
                                }
                            })
                            : ''}


                    </div>
                </div>
                <div className='modal-box-footer'>
                    {
                        isComeBack || userDataState?.daily_reward_claim == 1 ?
                            <button onClick={handleClose}>Come back tomorrow</button>
                            : <button onClick={claimPoint}>Claim Reward</button>
                    }

                </div>
            </div>

        </div>
    )
};
export default DailyRewardsBox;