import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import withNavigationCheck from './withNavigationCheck';

import { resetMiningPoints, getUserRankRequest } from "../_redux/actions/postsActions";
import { RootState } from "../_redux/reducers/rootReducer";
import Footer from './Footer';

interface UserData {
    id: number;
    first_name?: string;
    last_name?: string;
    username?: string;
    language_code?: string;
    is_premium?: boolean;
}

interface RankData {
    Recruit?: any;
    Private?: any;
    Corporal?: any;
    Sergeant?: any;
    Officer?: any;
    Lieutenant?: any;
    Captain?: any;
    Major?: any;
    Commander?: any;
    Colonel?: any;
    Brigadier?: any;
    General?: any;
    Marshal?: any;
}

const GameLevel: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { getUserRankList } = useSelector(
        (state: RootState) => state.posts
    );

    const [userData, setUserData] = useState<UserData | null>(null)
    const [isActiveRank, setIsActiveRank] = useState(0);
    const [userRankData, setUserRankData] = useState<RankData | null>(null);
    const [nextBtnDisebled, setNextBtnDisebled] = useState(false);
    const [prevBtnDisebled, setPreveBtnDisebled] = useState(false);
    const [rank, setRank] = useState(['Recruit', 'Private', 'Corporal', 'Sergeant', 'Officer', 'Lieutenant', 'Captain', 'Major', 'Commander', 'Colonel', 'Brigadier', 'General', 'Marshal']);
    const navigateToleval = () => {
        navigate("/");
    }

    const previousButton = (active: any) => {
        if (active == 0) {
            setIsActiveRank(0);
        } else {
            setIsActiveRank(isActiveRank - 1);
        }
    }

    const formatNumberWithoutFunctions = (num: any) => {
        let numStr = num.toString();
        let parts = numStr.split('.');
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? parts[1] : '';
        let reversedInteger = '';
        for (let i = integerPart.length - 1; i >= 0; i--) {
            reversedInteger += integerPart[i];
        }
        let withCommasReversed = '';
        for (let i = 0; i < reversedInteger.length; i++) {
            if (i > 0 && i % 3 === 0) {
                withCommasReversed += ',';
            }
            withCommasReversed += reversedInteger[i];
        }
        let formattedInteger = '';
        for (let i = withCommasReversed.length - 1; i >= 0; i--) {
            formattedInteger += withCommasReversed[i];
        }
        return decimalPart ? formattedInteger + '.' + decimalPart : formattedInteger;
    }

    const nextButton = (active: any) => {
        if ((isActiveRank + 1) == (rank.length)) {
            setIsActiveRank(rank.length - 1);
        } else {
            setIsActiveRank(isActiveRank + 1);
        }

    }
    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    useEffect(() => {
        dispatch(getUserRankRequest('' as any));
        dispatch(resetMiningPoints('' as any));
    }, [])
    useEffect(() => {
        if (getUserRankList && !isEmpty(getUserRankList)) {
            setUserRankData(getUserRankList);
        }
    }, [getUserRankList]);
    return (
        <React.Fragment>
            <section className="game-play-section">
                <div onClick={navigateToleval} className='backButton'>
                    <span className='arrow-left' />
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        {rank.map((data: any, index) => {
                            let rankNum = 1;
                            if (isActiveRank == index) {
                                return (
                                    <div className='rankCard'>
                                        <div onClick={() => previousButton(isActiveRank)} className='previousButton'>
                                            <span className='long-arrow-left' />
                                        </div>
                                        <div onClick={() => nextButton(isActiveRank)} className='nextButton'>
                                            <span className='long-arrow-right' />
                                        </div>
                                        <div className='rankData'>
                                            <div className='rankIconImg'>
                                                <img src={require(`../assets/images/ranks/${data}.jpeg`)} />
                                                <h3>{data}</h3>
                                            </div>
                                            <div className='rankUserLIst'>
                                                <ul className='userList'>
                                                    {
                                                        userRankData && userRankData[data] && userRankData[data] !== undefined && userRankData[data].map((rank: any, index) => {
                                                            return (<li>
                                                                <div className='profileIcon'>
                                                                    <img src={require('../images/soldier.png.png')} />
                                                                </div>
                                                                <div className='profileDetail'>
                                                                    <p>{rank?.first_name} {rank?.last_name}<br /> <span className='currence'>$</span> {formatNumberWithoutFunctions(rank?.amount)}</p>
                                                                </div>
                                                                <div className='rankNo'>
                                                                    <span>{rankNum++}</span>
                                                                </div>
                                                            </li>)
                                                        })
                                                    }
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                        })}

                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
};
export default withNavigationCheck(GameLevel);