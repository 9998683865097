import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';
import withNavigationCheck from './withNavigationCheck';
import { withdrawSystemRequest, resetMiningPoints } from '../_redux/actions/postsActions';
import Footer from './Footer';
import Navbar from './Navbar';
import ReferralSystem from './ReferralSystem';
import { RootState } from "../_redux/reducers/rootReducer";

import ReactJsAlert from "reactjs-alert"

interface InputRequestData {
    user_id: number | undefined;
    coin_name?: string | undefined;
    coin_address?: string | undefined;
    coin_network?: string | undefined;
}

let currentRate = 0.00631726;

const WithdrawSystem: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isProgress, userDataState, withdrawResponce, walletBalanceResult } = useSelector(
        (state: RootState) => state.posts
    );
    const [isAlertActive, setIsAlertActive] = useState(false)
    const [isInsufficient, setIsInsufficient] = useState(false);
    const [totalBonusValue, setTotalBonus] = useState(0);
    const [isWithdrawBonus, setWithdrawBonus] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const [withDrawField, setWithDrawField] = useState<InputRequestData | any>(null)

    useEffect(() => {
        dispatch(resetMiningPoints('' as any));
    }, [])

    const withDrawInput = (event: any) => {
        if (event.target.name == 'coin_name') {
            console.log('event.target.value', event.target.value);
            if (Number(event.target.value) > Number(walletBalanceResult?.wallet_balance)) {
                alert('You do not have enough coins for withdrawal!');
            } else {
                setWithDrawField({ ...withDrawField, [`${event.target.name}`]: event.target.value });
            }
        } else {
            setWithDrawField({ ...withDrawField, [`${event.target.name}`]: event.target.value });
        }
    }
    const isAlertActiveFun = () => {
        setIsAlertActive(false);
        navigate(`/`);
    }
    const isInsufficientFun = () => {
        setIsInsufficient(false);
    }
    const submitHandler = (event: any) => {
        setIsSubmit(true);
        let inputRequest = {
            user_id: userDataState?.user_id,
            coin_name: event,
            coin_address: 0.0,
            coin_network: 0.0
        } as any
        dispatch(withdrawSystemRequest(inputRequest))
    }
    useEffect(() => {
        setWithDrawField({ ...withDrawField, [`user_id`]: userDataState?.user_id });
    }, [])

    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        if (isSubmit && (withdrawResponce && !isEmpty(withdrawResponce))) {
            if (withdrawResponce?.message == "success") {
                setIsAlertActive(true);
            }
        }
    }, [withdrawResponce])
    useEffect(() => {
        let walletKeyArgs = Object.keys(walletBalanceResult);
        let walletKeyArrObj = walletKeyArgs && walletKeyArgs.length > 0 ?
            walletKeyArgs.filter((keys) => keys !== "status" && keys !== "wallet_balance")
            : [];
        let mapBousObj = walletKeyArrObj.map((dataKey) => walletBalanceResult[dataKey]);
        const totalBonus = mapBousObj.reduce(
            (accumulator, currentValue) => Number(accumulator) + Number(currentValue),
            0,
        );
        setTotalBonus(totalBonus);
    }, [walletBalanceResult])
    const withdrawBonus = () => {
        setWithdrawBonus(true);
    }
    const cancelWithDraw = () => {
        setWithdrawBonus(false);

    }
    const checkWithDraw = () => {
        setIsInsufficient(true);
    }
    return (
        <React.Fragment>
            <Navbar />
            <section style={{ paddingTop: "180px" }} className="game-play-section">
                {
                    isWithdrawBonus ?
                        <div className="container" style={{ height: "820px" }}>
                            <div className="row" style={{ padding: "25px" }}>
                                <div className="col-12 task-box">
                                    <p><small>Current Rate</small></p>
                                    <h4>{currentRate}</h4>
                                </div>
                                <div className="col-12 task-box">
                                    <div className="row">
                                        <div className="col-6">
                                            <span><small>From</small></span>
                                            <h6>0.00</h6>
                                        </div>
                                        <div className="col-6">
                                            <span><small>Balance</small></span>
                                            <h6>{(totalBonusValue / 1000).toFixed(8)} NOLZ</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ position: "relative" }} className="col-12">
                                        <div style={{ position: "absolute" }}>
                                            <img style={{ width: "50px" }} src={require('../images/Convert-Icon.png')} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 task-box">
                                    <div className="row">
                                        <div className="col-6">
                                            <span><small>To</small></span>
                                            <h6>0.00</h6>
                                        </div>
                                        <div className="col-6">
                                            <span><small>Balance</small></span>
                                            <h6>{(Number((totalBonusValue / 1000).toFixed(8)) * Number(currentRate)).toFixed(8)} USDT</h6>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <p>Minimum withdraw: 0.05</p>
                                    <p>Transaction fee:  0.035</p>
                                    <p>Your are about to withdraw <strong>{
                                        (Number((Number((totalBonusValue / 1000).toFixed(8)) * Number(currentRate)).toFixed(8)) - Number(0.035)) > 0 ?
                                            (Number((Number((totalBonusValue / 1000).toFixed(8)) * Number(currentRate)).toFixed(8)) - Number(0.035)).toFixed(8) : '0.0000'}</strong></p>
                                </div>
                                <div className="col-12">
                                    {isProgress ? <ReactLoading type="balls" color="#fff" height={'10%'} width={'30%'} /> : ''}
                                    <div className='row'>
                                        {Number((Number((Number((totalBonusValue / 1000).toFixed(8)) * Number(currentRate)).toFixed(8)) - Number(0.035)).toFixed(8)) > 0.05 ?
                                            <div onClick={() => submitHandler((Number((Number((totalBonusValue / 1000).toFixed(8)) * Number(currentRate)).toFixed(8)) - Number(0.035)).toFixed(8))} className="col-5 task-box" style={{ paddingTop: "10px", textAlign: "center", marginTop: "30px" }}>
                                                <h4>Confirm</h4>
                                            </div>
                                            : <div onClick={() => checkWithDraw()} className="col-5 task-box" style={{ paddingTop: "10px", textAlign: "center", marginTop: "30px" }}>
                                                <h4>Confirm</h4>
                                            </div>
                                        }
                                        <div onClick={cancelWithDraw} className="col-5 task-box" style={{ paddingTop: "10px", textAlign: "center", marginTop: "30px", marginLeft: "30px" }}>
                                            <h4>Cancel</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :

                        <div className="container" style={{ height: "820px" }}>
                            <div className="row">
                                <div className="col-12 earnMoreCoins">
                                    <h2>Unlock Bonus</h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>BONUS:</th>
                                                <th>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>GAP</td>
                                                <td>{walletBalanceResult?.gap_bonus}</td>
                                            </tr>
                                            <tr>
                                                <td>EPT</td>
                                                <td>{walletBalanceResult?.ept_bonus}</td>
                                            </tr>
                                            <tr>
                                                <td>BSR</td>
                                                <td>{walletBalanceResult?.bsr_bonus}</td>
                                            </tr>
                                            <tr>
                                                <td>FGS</td>
                                                <td>{walletBalanceResult?.fgs_bonus}</td>
                                            </tr>
                                            <tr>
                                                <td>L&C</td>
                                                <td>{walletBalanceResult?.landc_bonus}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Bonus</td>
                                                <td>{totalBonusValue}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>APV<span style={{ marginLeft: "150px" }}>{totalBonusValue}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>APV / 1000 <span style={{ marginLeft: "70px" }}>{(totalBonusValue / 1000).toFixed(8)} NOLZ</span></p>
                                </div>
                                <div className="col-12" style={{ textAlign: "center", marginTop: "30px" }}>
                                    <button onClick={withdrawBonus} className='btn btn-default withdraw-btn'>Withdraw</button>
                                </div>
                            </div>

                        </div>
                }
                <ReactJsAlert
                    status={isAlertActive}
                    type="success"
                    title="Processing to arrive within 48 hours!"
                    Close={() => isAlertActiveFun()}
                />
                <ReactJsAlert
                    status={isInsufficient}
                    type="error"
                    title="You do not have insufficient balance for withdraw!"
                    Close={() => isInsufficientFun()}
                />
            </section>
            <Footer />
        </React.Fragment>
    )
};
export default withNavigationCheck(WithdrawSystem);