import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { resetMiningPoints } from '../../_redux/actions/postsActions';
import Footer from '../Footer';
import Navbar from '../Navbar';
import withNavigationCheck from '../withNavigationCheck';

interface UserData {
    id: number;
    first_name?: string;
    last_name?: string;
    username?: string;
    language_code?: string;
    is_premium?: boolean;
}

const Support: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetMiningPoints('' as any));
    }, [])
    return (
        <React.Fragment>
            <Navbar />
            <div className="hero-area hero-area2 hero-text">
                &nbsp;
            </div>
            <section className="game-play-section">
                <div className="container">
                    <div style={{ height: '860px',paddingTop:"100px" }} className="row justify-content-center">
                        <a style={{color:"#fff"}} href='http://www.nolubz.com/contact' target='_blank'>Click Here For Support</a>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
};
export default withNavigationCheck(Support);